import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';

const mock = [
  {
    media: 'https://cache.careers360.mobi/media/schools/social-media/media-gallery/8350/2019/7/20/National%20Public%20School-Art_Craft.jpg',
    href: '/not-found?section=prekg',
    companyLogo:
      'Pre-KG',
    description:
      'Pre-KG',
  },
  {
    media: 'https://cache.careers360.mobi/media/schools/social-media/media-gallery/8350/2019/7/20/National%20Public%20School-Computer%20Lab.jpg',
    href: '/not-found?section=11',
    companyLogo:
      'Grade 11',
    description:
      'Grade 11',
  },
  {
    media: 'https://cache.careers360.mobi/media/schools/social-media/media-gallery/8350/2019/7/20/National%20Public%20School-Computer%20Lab.jpg',
    href: '/not-found?section=1to9',
    companyLogo:
      'Grade 1-9',
    description:
      'Grade 1-9',
  },
  {
    media: 'https://cache.careers360.mobi/media/schools/social-media/media-gallery/8350/2019/7/20/National%20Public%20School-Computer%20Lab.jpg',
    href: '/not-found?section=mont',
    companyLogo:
      'Montessori',
    description:
      'Montessori',
  },
  // {
  //   media: 'https://assets.maccarianagency.com/backgrounds/img1.jpg',
  //   companyLogo:
  //     'https://assets.maccarianagency.com/svg/logos/hubspot-original.svg',
  //   description:
  //     'HubSpot offers a full platform of marketing, sales, customer service, and CRM software — plus the methodology, resources, and support — to help businesses.',
  // },
  // {
  //   media: 'https://assets.maccarianagency.com/backgrounds/img3.jpg',
  //   companyLogo:
  //     'https://assets.maccarianagency.com/svg/logos/mapbox-original.svg',
  //   description:
  //     'Integrate custom live maps, location search, and turn-by-turn navigation into any mobile or web app with Mapbox APIs & SDKs. Get started for free.',
  // },
  // {
  //   media: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
  //   companyLogo:
  //     'https://assets.maccarianagency.com/svg/logos/netflix-original.svg',
  //   description:
  //     'Watch Netflix movies & TV shows online or stream right to your smart TV, game console, PC, Mac, mobile, tablet and more.',
  // },
  // {
  //   media: 'https://assets.maccarianagency.com/backgrounds/img22.jpg',
  //   companyLogo:
  //     'https://assets.maccarianagency.com/svg/logos/paypal-original.svg',
  //   description:
  //     'PayPal is the faster, safer way to send money, make an online payment, receive money or set up a merchant account.',
  // },
  // {
  //   media: 'https://assets.maccarianagency.com/backgrounds/img24.jpg',
  //   companyLogo:
  //     'https://assets.maccarianagency.com/svg/logos/slack-original.svg',
  //   description:
  //     'Slack is a new way to communicate with your team. It\'s faster, better organized, and more secure than email.',
  // },
];

const Form = () => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Box>
      {/* <Box marginBottom={4}>
       
        <Box
          component={Typography}
          fontWeight={70}
          variant={'h4'}
          align={'center'}
        >
          Learn more on our Scholastic and Co-Scholastic 
        </Box>
      </Box> */}
      <Grid container spacing={4} justifyContent="center">
        {mock.map((item, i) => (
          <Grid item xs={12} sm={4} md={3} key={i}>
            <Box
              // component={'a'}
              href={''}
              display={'block'}
              width={1}
              height={1}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                borderRadius={2}
                display={'flex'}
                flexDirection={'column'}
              >
                <CardMedia
                  image={item.media}
                  title={item.title}
                  sx={{
                    height: 240,
                  }}
                />
                <Box component={CardContent}>
                  <Box maxWidth={100} marginY={2}>
                    <Box
                      // component="text"
                      height={1}
                      width={1}
                      src={item.companyLogo}
                      alt="..."
                      sx={{
                        filter: mode === 'dark' ? 'contrast(0)' : 'none',
                      }}
                    />
                  </Box>
                  <Typography
                    align={'left'}
                    variant={'h4'}
                    color="textSecondary"
                  >
                    {item.description}
                  </Typography>
                </Box>
                <Box flexGrow={1} />
                <Box component={CardActions} justifyContent={'flex-start'}>
                  <Button
                    size="large"
                    href={item.href}
                    endIcon={
                      <svg
                        width={16}
                        height={16}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    }
                  >
                    Click here
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Form;
